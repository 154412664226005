<template>
  <div class="row" ref="proxy">
    <div class="col-md-12 col-lg-12">
      <!-- 서명보기 -->
      <c-card title="서명보기" class="cardClassDetailInfo" topClass="topcolor-orange">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn v-if="data.tbmCompleteFlag !== 'Y'" label="" icon="check" @btnClicked="acceptSignature" />
            <c-btn v-if="data.tbmCompleteFlag !== 'Y'" icon="delete_forever" label="" color="red" :showLoading="false" @btnClicked="eraseSignature" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="row">
            <div class="col-xs-12 col-sm-12">
              <VueSignaturePad width="200px" height="200px" ref="signaturePad" />
            </div>
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>
<script>
import VueSignaturePad from 'vue-signature-pad';
import Vue from 'vue';
let signaturePad = null;
Vue.use(VueSignaturePad)
export default {
  name: 'signature-pop',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          electronSignature: '',
        }
      },
    },
    data: {
      type: Object,
      default(){
        return {
          tbmCompleteFlag: '',
        }
      },
    },
    rowIndex: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      if (!this.$refs.signaturePad) {
        return;
      }
      this.windowWidth = (document.documentElement.clientWidth - 45) + 'px';
      signaturePad = this.$refs.signaturePad
      
      if (this.data.tbmCompleteFlag === 'Y') {
        signaturePad.lockSignaturePad();
      }

      if (this.popupParam.electronSignature) {
        signaturePad.fromDataURL(this.popupParam.electronSignature);
      }
    },
    acceptSignature() {
      var data = ''
      if (signaturePad.saveSignature()) {data = signaturePad.saveSignature().data;}
      this.$emit('signCallback2', data, this.rowIndex);
    },
    eraseSignature() {
      signaturePad.clearSignature();
      this.popupParam.electronSignature = '';
    },
    reset() {
      Object.assign(this.$data.info, this.$options.data().info);
    },
  }
};
</script>